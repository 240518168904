const userName = document.getElementById("name");
const phone = document.getElementById("phone");
const file = document.getElementById("review-screen");
const fileError = document.querySelector('file-error')
document.addEventListener('submit', async (e) => {
    if(!phone.validity.valid || !userName.validity.valid || !file.validity.valid) {
        showError()
        e.preventDefault()
        return
    }
    e.preventDefault()
    const form = e.target
    const promise = await fetch('/api/send-mail.php', {
        method: 'POST',
        body: new FormData(form)
    })
    const response = await promise.json()
    document.querySelector('.message__backdrop .message').innerHTML = response.message
    document.querySelector('.message__backdrop').classList.add('show')
    document.body.style.overflow = 'hidden'
    if ( response.code == 200 ) {
        form.name.value = ''
        form.phone.value = ''
        form.message.value = ''
        form['review-screen'].value = ''
        form.querySelector('.btn--file').innerHTML = 'загрузить файл'
      
    }
    setTimeout(() => {
        document.querySelector('.message__backdrop').classList.remove('show')
        document.body.style.overflow = 'auto'
    }, 5000)
})

function showError() {
    
    if (userName.validity.valueMissing) {
        userName.nextElementSibling.textContent = 'Заполните поле'
    } 
    if (phone.validity.valueMissing) {
        phone.nextElementSibling.textContent = 'Заполните поле'
    } 
    if (file.validity.valueMissing) {
        file.nextElementSibling.textContent = 'Загрузите скриншот отзыва'
    }

    setTimeout(() => {
        userName.nextElementSibling.textContent = ''
        phone.nextElementSibling.textContent = ''
        file.nextElementSibling.textContent = ''
    },30000)
    
    
}   