import VanillaTilt from 'vanilla-tilt';
import './tel'
import './input-file'
import './form'
document.addEventListener('click', (e) => {
    console.log(e.target)
    if (!e.target.closest('[data-target]')) return
    const targetId = e.target.closest('[data-target]').dataset.target
    const targetEl = document.querySelector(`[data-step="${targetId}"]`)
    const activeEl = e.target.closest('.step')
    const bodyInnerEl = document.querySelector('.body-inner')
    // bodyInnerEl.style.overflow = 'hidden'
    activeEl.addEventListener('transitionend', () => {
        activeEl.classList.remove('leave-to')
        activeEl.classList.add('enter-from')
        // setTimeout(function ()  {

        //     // bodyInnerEl.style.overflow = 'auto'
        // })
    }, {once: true })

    

    activeEl.classList.add('leave-to')
    targetEl.classList.remove('enter-from')
   
})

function setBodyHeight () {
    let bodyHeight = 0
    document.querySelectorAll('.step').forEach(el => {
        bodyHeight = el.scrollHeight > bodyHeight ? el.scrollHeight : bodyHeight
    })
    if (window.innerWidth < 660) {
        document.querySelector('.body-inner').style.height = `${bodyHeight + 265}px`
    } 
}

document.addEventListener('DOMContentLoaded', (e) => {
    setBodyHeight()
})
window.addEventListener('resize', (e) => {
    setBodyHeight()
})


document.addEventListener('click', (e) => {
    if (!e.target.closest('.message__backdrop .btn-close')) return
    document.querySelector('.message__backdrop').classList.remove('show')
})
// VanillaTilt.init(document.querySelectorAll(".card"), {
//     glare: true,
//     "max-glare": 0.3,
//     max: 5
// })

